<template>
  <contact-card
    class="tw-m-8"
    @updated="onEdit"
    @removed="onDelete"
    :loading="loading"
    :type="type"
    :contact="contact"
  ></contact-card>
</template>

<script>
import ContactCard from "@/components/Cards/ContactCard.vue";
import { useContactQuery } from "@/generated/backend";
import router from "@/router";
import { useResult } from "@vue/apollo-composable";
import { useContactPilverQuery } from "../generated/backend";

export default {
  components: { ContactCard },
  setup() {
    const type = router.currentRoute.params.type;
    const contactId = router.currentRoute.params.id;
    const { loading, result, refetch } =
      type === "Pilver"
        ? useContactPilverQuery({
            id: contactId,
          })
        : useContactQuery({
            id: contactId,
          });

    const contact = useResult(result, null, (r) =>
      type === "Pilver" ? r.contactPilver : r.contact
    );

    return {
      loading,
      contact,
      type,
      onDelete: () => router.push({ name: "Contacts" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
