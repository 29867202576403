<template>
  <v-row>
    <v-col cols="12" sm="12">
      <company-combobox
        :value="value.Name"
        :type="type"
        @input="update({ Name: $event })"
      ></company-combobox>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Webpage"
        @input="update({ Webpage: $event })"
        label="Webová stránka"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Address"
        @input="update({ Address: $event })"
        label="Adresa"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <company-language-autocomplete
        :value="value.Language"
        :type="type"
        @input="update({ Language: $event })"
      ></company-language-autocomplete>
    </v-col>
    <v-col cols="12" sm="12">
      <company-focus-autocomplete
        :value="value.Focus"
        :type="type"
        @input="update({ Focus: $event })"
      ></company-focus-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import CompanyLanguageAutocomplete from "@/components/Autocomplete/CompanyLanguageCombobox.vue";
import CompanyCombobox from "@/components/Autocomplete/CompanyCombobox.vue";
import CompanyFocusAutocomplete from "@/components/Autocomplete/CompanyFocusCombobox.vue";

export default {
  components: {
    CompanyLanguageAutocomplete,
    CompanyCombobox,
    CompanyFocusAutocomplete,
  },
  props: {
    value: {
      type: Object,
    },
    type: { type: String },
  },
  setup(props, { emit }) {
    return {
      update: (updated) => {
        emit("input", { ...props.value, ...updated });
      },
    };
  },
};
</script>

<style></style>
