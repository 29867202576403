<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-textarea
        dense
        :value="value.Description"
        @input="update({ Description: $event })"
        label="Popis"
      ></v-textarea>
    </v-col>
    <v-col cols="12" sm="12">
      <project-state-autocomplete
        :value="value.State"
        @input="update({ State: $event })"
      ></project-state-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import User from "../../../services/auth";
import { ref } from "vue-demi";
import ProjectStateAutocomplete from "@/components/Autocomplete/ProjectStateAutocomplete.vue";

export default {
  components: {
    ProjectStateAutocomplete,
  },
  props: {
    value: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const lazyValue = ref(props.value);
    const update = (updated) => {
      lazyValue.value = { ...lazyValue.value, ...updated };
      emit("input", lazyValue.value);
    };

    const onNewProjectCreated = (project) => {
      update({ project: { Id: project.Id, Name: project.Name } });
    };

    return {
      update,
      User,
      onNewProjectCreated,
    };
  },
};
</script>

<style></style>
