<template>
  <delete-dialog-base @remove="remove" :loading="deletingProject">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline>Odstrániť projekt {{ lazyProject.Name }}</template>
    <template #form>
      Naozaj chcete odstrániť projekt {{ lazyProject.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import { useDeleteOneProjectMutation } from "@/generated/backend";
import { ref, watch } from "vue-demi";
import User from "@/services/auth";

export default {
  components: { DeleteDialogBase },
  props: {
    project: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyProject = ref(props.project);
    watch(
      () => props.project,
      (c) => (lazyProject.value = c)
    );

    const {
      mutate: removeProject,
      loading: deletingProject,
      onDone: deletingFinished,
    } = useDeleteOneProjectMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeProject({
        input: { id: lazyProject.value.Id },
      });
    };

    return {
      remove,
      lazyProject,
      deletingProject,
      User,
    };
  },
};
</script>

<style></style>
