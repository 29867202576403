<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <middleman-card
      @updated="onEdit"
      @removed="onDelete"
      :loading="loading"
      :middleman="middleman"
    ></middleman-card>
  </div>
</template>

<script>
import MiddlemanCard from "@/components/Cards/MiddlemanCard.vue";
import { useMiddlemanQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
import { computed, ref } from "vue-demi";

export default {
  components: { MiddlemanCard },
  setup(props, { emit }) {
    const table = ref(null);
    const { loading, result, refetch } = useMiddlemanQuery({
      id: router.currentRoute.params.id,
    });

    const middleman = useResult(result, null, (r) => r.middleman);

    return {
      table,
      loading,
      middleman,
      onDelete: () => router.push({ name: "Middlemans" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
