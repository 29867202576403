<template>
  <edit-dialog-base @update="update" :loading="creatingWorker">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť subdodávateľa {{ lazyWorker.Name }}</template>
    <template #form>
      <worker-form v-model="lazyWorker"></worker-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import WorkerForm from "./Forms/WorkerForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneWorkerMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
export default {
  components: { EditDialogBase, WorkerForm },
  props: {
    worker: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyWorker = ref(props.worker);
    watch(
      () => props.worker,
      (c) => (lazyWorker.value = c)
    );

    const {
      mutate: updateWorker,
      loading: creatingWorker,
      onDone: creatingFinished,
    } = useUpdateOneWorkerMutation({});

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        createdBy,
        ...updateItem
      } = lazyWorker.value;
      updateItem.attachments = updateItem.attachments.map((a) => {
        const { UpdatedAt, CreatedAt, DeletedAt, __typename, ...attachment } =
          a;
        return attachment;
      });
      updateWorker({
        input: { update: updateItem, id: lazyWorker.value.Id },
      });
    };

    return {
      update,
      lazyWorker,
      creatingWorker,
    };
  },
};
</script>

<style></style>
