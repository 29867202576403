<template>
  <companies-table
    type="Pilver"
    class="tw-max-w-7xl tw-mx-auto tw-m-8"
  ></companies-table>
</template>

<script>
import CompaniesTable from "../components/Tables/CompaniesTable.vue";

export default {
  components: { CompaniesTable },
};
</script>

<style></style>
