<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          v-if="contact && contact.company"
          :to="{
            name: 'ContactDetail',
            params: { type: type, id: contact.Id },
          }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-phone-forward-outline</v-icon
          >
          Kontaktovanie
          {{ name }}
          z
          {{ $luxon(contact.CreatedAt) }}
        </router-link>
        <a
          href="#"
          v-else
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-phone-forward-outline</v-icon
          >
          Kontaktovanie {{ name }}
        </a>
        <v-spacer></v-spacer>
        <edit-contact-dialog
          @updated="$emit('updated')"
          :contact="contact"
          :company="company"
          :type="type"
          v-slot:default="{ on, attrs }"
          ref="editContact"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-contact-dialog>
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row v-if="!contact">
            <template #value>Žiadny kontakt</template>
          </card-row>
          <template v-else>
            <card-row>
              <template #title>Kontaktovaná osoba</template>
              <template #value>{{ contact.ContactPerson }}</template>
            </card-row>
            <card-row>
              <template #title>Telefón</template>
              <template #value
                ><a :href="'tel:' + contact.Phone">{{
                  contact.Phone
                }}</a></template
              >
            </card-row>
            <card-row>
              <template #title>Email</template>
              <template #value
                ><a :href="'mailto:' + contact.Email">{{
                  contact.Email
                }}</a></template
              >
            </card-row>
            <card-row>
              <template #title>Záujem</template>
              <template #value>{{ contact.Interest }}</template>
            </card-row>
            <card-row>
              <template #title>Popis</template>
              <template #value>{{ contact.Description }}</template>
            </card-row>
            <card-row>
              <template #title>Firma</template>
              <template #value
                ><router-link
                  v-if="contact.company"
                  :to="{
                    name: 'CompanyDetail',
                    params: { id: contact.company.Id },
                  }"
                  >{{ contact.company.Name }}</router-link
                >
                <span v-else>Vymazaná</span></template
              >
            </card-row>
            <card-row>
              <template #title>Používateľ</template>
              <template #value
                ><router-link
                  :to="{
                    name: 'UserDetail',
                    params: { id: contact.contactedBy.Id },
                  }"
                  >{{ contact.contactedBy.Username }}</router-link
                ></template
              >
            </card-row>
            <card-row>
              <template #title>Pridané</template>
              <template #value>{{ contact.CreatedAt | luxon("ff") }}</template>
            </card-row>
          </template>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import { nextTick, onMounted, ref, on, watch } from "vue-demi";
import EditContactDialog from "../Dialogs/EditContactDialog.vue";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
import router from "@/router";
export default {
  components: { CardRow, CardLoader, EditContactDialog },
  props: {
    loading: { type: Boolean },
    contact: { type: Object },
    company: { type: Object },
    showEditContact: { type: Boolean },
    type: { type: String },
  },
  setup(props, { refs }) {
    const editContact = ref(null);
    const name = ref("vymazane");

    watch(
      () => props.loading,
      (newLoading) => {
        if (!newLoading) {
          if (props.company) name.value = props.company.Name;
          else if (props.contact) {
            if (props.contact.company) name.value = props.contact.company.Name;
          }

          if (props.showEditContact) {
            nextTick(() => {
              refs.editContact.open();
              router.replace({
                params: { ...router.currentRoute.params, on: undefined },
              });
            });
          }
        }
      }
    );

    return {
      name,
      editContact,
    };
  },
};
</script>

<style></style>
