var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.query,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":Object.assign({}, {company: { Id: { eq: _vm.company.Id } }},
    (_vm.company.contact
      ? {
          Id: {
            neq: _vm.company.contact.Id,
          },
        }
      : {})),"tableTitle":"História kontaktovania"},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"UpdatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(_vm._s(_vm._f("luxon")(item.UpdatedAt,"ff"))+" ")]}},{key:"contactedBy",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.contactedBy ? item.contactedBy.Username : "Vymazaný")+" ")]}},{key:"Email",fn:function(ref){
  var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.Email}},[_vm._v(_vm._s(item.Email))])]}},{key:"remove-dialog",fn:function(ref){
  var item = ref.item;
return [_c('delete-contact-dialog',{attrs:{"contact":item},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }