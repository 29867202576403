var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.query,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":Object.assign({}, (_vm.fromDate
      ? {
          UpdatedAt: {
            gte: _vm.fromDate,
          },
        }
      : {}),
    (_vm.status
      ? {
          Status: {
            eq: _vm.status,
          },
        }
      : {})),"tableTitle":"Projekty"},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ProjectDetail', params: { id: item.Id } }}},[_vm._v(_vm._s(item.Name))])]}},{key:"UpdatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$luxon(item.UpdatedAt))+" ")]}},{key:"State",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.projectChange ? item.projectChange.State : "")+" ")]}},{key:"create-dialog",fn:function(){return [_c('calendar',{attrs:{"label":"Zobraziť od"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('project-state-autocomplete',{staticClass:"tw-w-40 mx-4",model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('create-project-dialog',{on:{"created":_vm.reloadTable}})]},proxy:true},{key:"edit-dialog",fn:function(ref){
  var item = ref.item;
return [_c('edit-project-dialog',{attrs:{"project":item},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
  var item = ref.item;
return [_c('delete-project-dialog',{attrs:{"project":item},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }