export const getEmptyCompany = () => ({
  Name: "",
  Webpage: "",
  Address: "",
  Focus: "",
  Language: "",
});

export const getEmptyProject = () => ({
  Name: "",
  Type: "",
  Address: "",
  Language: "",
  Manager: "",
  ManagerContact: "",
});

export const getEmptyContact = () => ({
  ContactPerson: "",
  Email: "",
  Phone: "",
  Interest: "",
  companyId: null,
});

export const getEmptyProjectChange = () => ({
  State: "",
  Description: "",
  projectId: null,
});

export const getEmptyUser = () => ({
  Username: "",
  Password: "",
  Email: "",
  Status: "",
  Role: "",
});

export const getEmptyWorker = () => ({
  Name: "",
  Phone: "",
  Email: "",
  Note: "",
  Profession: "",
  Type: "",
  attachments: [],
});

export const getEmptyMiddleman = () => ({
  Name: "",
  Phone: "",
  Email: "",
  Note: "",
  attachments: [],
});

export const getEmptyContract = (type) => ({
  Name: "",
  Description: "",
  Type: type,
  Year: 2023,
  attachments: [],
});

export const getEmptyCar = () => ({
  Name: "",
  Description: "",
  Evidence: "",
  Type: "",
  attachments: [],
});
