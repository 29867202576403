<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Rok"
  ></combobox-base>
</template>

<script>
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
import {
  useContractYearsQuery,
  useContractPilverYearsQuery,
} from "../../generated/backend";
export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
    type: { type: String },
  },
  setup(props) {
    return {
      query: (v) =>
        props.type === "Pilver"
          ? useContractPilverYearsQuery()
          : useContractYearsQuery(),
      itemsGetter: (result) =>
        props.type === "Pilver"
          ? useResult(result, [], (d) => d.contractPilverYears.sort())
          : useResult(result, [], (d) => d.contractYears.sort()),
    };
  },
};
</script>
<style></style>
