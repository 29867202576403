<template>
  <contracts-table
    class="tw-max-w-7xl tw-mx-auto tw-m-8"
    tableType="Worker"
    companyType="Pilver"
  ></contracts-table>
</template>

<script>
import ContractsTable from "../components/Tables/ContractsTable.vue";

export default {
  components: { ContractsTable },
};
</script>

<style></style>
