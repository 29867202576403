import NotificationsService from "./NotificationsService";

let NOTIFICATION_ID = 0;

export default class Notification {
  type: "error" | "success" | "info" | "warning" = "info";
  title = "notification title";
  message = "notification text";
  id: number;
  timeout: number | undefined;
  isDestroyed = false;

  constructor(config: {
    type: "error" | "success" | "info" | "warning";
    title: string;
    message: string;
    delay?: number;
  }) {
    this.type = config.type;
    this.title = config.title;
    this.message = config.message;

    this.id = NOTIFICATION_ID++;
  }

  get Id() {
    return this.id;
  }

  public publish() {
    NotificationsService.Add(this);
  }
}
