var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.query,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":Object.assign({}, (_vm.olderThan
      ? {
          contact: {
            CreatedAt: {
              lte: _vm.olderThan,
            },
            Interest: {
              eq: 'Možno',
            },
          },
        }
      : {}),
    (_vm.interest
      ? {
          contact: {
            Interest: Object.assign({}, (_vm.interest === 'Neuvedený' ? { is: null } : { eq: _vm.interest })),
          },
        }
      : {})),"tableTitle":"Notifikácie","sortDesc":true},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"create-dialog",fn:function(){return [_c('calendar',{attrs:{"label":"Staršie ako"},model:{value:(_vm.olderThan),callback:function ($$v) {_vm.olderThan=$$v},expression:"olderThan"}}),_c('interest-autocomplete',{staticClass:"tw-w-40 mx-4",model:{value:(_vm.interest),callback:function ($$v) {_vm.interest=$$v},expression:"interest"}})]},proxy:true},{key:"Name",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'CompanyDetail', params: { id: item.Id } }}},[_vm._v(_vm._s(item.Name))])]}},{key:"Webpage",fn:function(ref){
  var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.Webpage}},[_vm._v(_vm._s(item.Webpage))])]}},{key:"UpdatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact ? _vm.$luxon(item.contact.CreatedAt, "relative") : "Nikdy")+" ")]}},{key:"interest",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact.Interest)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }