<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          v-if="projectChange && projectChange.project"
          :to="{
            name: 'ProjectChangeDetail',
            params: { id: projectChange.Id },
          }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-phone-forward-outline</v-icon
          >
          Podrobnosti
          {{ name }}
          z
          {{ $luxon(projectChange.CreatedAt) }}
        </router-link>
        <a
          href="#"
          v-else
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-phone-forward-outline</v-icon
          >
          Podrobnosti {{ name }}
        </a>
        <v-spacer></v-spacer>
        <edit-project-change-dialog
          @updated="$emit('updated')"
          :projectChange="projectChange"
          :project="project"
          v-slot:default="{ on, attrs }"
          ref="editProjectChange"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-project-change-dialog>
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row v-if="!projectChange">
            <template #value>Žiadny stav</template>
          </card-row>
          <template v-else>
            <card-row>
              <template #title>Popis</template>
              <template #value>
                <pre>{{ projectChange.Description }}</pre>
              </template>
            </card-row>
            <card-row>
              <template #title>Stav</template>
              <template #value>{{ projectChange.State }}</template>
            </card-row>
            <card-row>
              <template #title>Projekt</template>
              <template #value
                ><router-link
                  v-if="projectChange.project"
                  :to="{
                    name: 'ProjectDetail',
                    params: { id: projectChange.project.Id },
                  }"
                  >{{ projectChange.project.Name }}</router-link
                >
                <span v-else>Vymazaný</span></template
              >
            </card-row>
            <card-row>
              <template #title>Používateľ</template>
              <template #value
                ><router-link
                  :to="{
                    name: 'UserDetail',
                    params: { id: projectChange.modifiedBy.Id },
                  }"
                  >{{ projectChange.modifiedBy.Username }}</router-link
                ></template
              >
            </card-row>
            <card-row>
              <template #title>Pridané</template>
              <template #value>{{ projectChange.CreatedAt | luxon }}</template>
            </card-row>
          </template>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import { nextTick, onMounted, ref, watch } from "vue-demi";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
import router from "@/router";
import EditProjectChangeDialog from "../Dialogs/EditProjectChangeDialog.vue";
import ProjectStateAutocomplete from "../Autocomplete/ProjectStateAutocomplete.vue";
export default {
  components: {
    CardRow,
    CardLoader,
    EditProjectChangeDialog,
  },
  props: {
    loading: { type: Boolean },
    projectChange: { type: Object },
    project: { type: Object },
    showEditProjectChange: { type: Boolean },
  },
  setup(props, { refs }) {
    const editProjectChange = ref(null);
    const name = ref("Vymazaný");

    watch(
      () => props.loading,
      (newLoading) => {
        if (!newLoading) {
          if (props.project) name.value = props.project.Name;
          else if (props.projectchange) {
            if (props.projectchange.project)
              name.value = props.projectchange.project.Name;
          }

          if (props.showEditProjectChange) {
            nextTick(() => {
              refs.editProjectChange.open();
              router.replace({
                params: { ...router.currentRoute.params, on: undefined },
              });
            });
          }
        }
      }
    );

    return {
      name,
      editProjectChange,
    };
  },
};
</script>

<style></style>
