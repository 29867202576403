<template>
  <file-pond
    name="test"
    ref="pond"
    label-idle="Nahrať novú prílohu"
    credits="false"
    allow-multiple="true"
    v-bind:server="server"
    allowReplace="false"
    allowRevert="true"
    allowRemove="true"
    allowReorder="false"
    :files="files"
    @processfile="onProcess"
    @removefile="onRemove"
    @processfilerevert="onRevert"
  />
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { ref, watch } from "@vue/composition-api";

// Create component
const FilePond = vueFilePond();

export default {
  components: { FilePond },
  props: {
    value: { type: Array },
  },
  setup(props, { emit }) {
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // https://stackoverflow.com/questions/24289182/how-to-strip-type-from-javascript-filereader-base64-string
          return resolve(reader.result.split(",")[1]);
        };
        reader.onerror = (error) => reject(error);
      });
    };

    const server = {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options
      ) => {
        progress(true, 100, 100);
        load();
      },
      remove: (source, load, error) => {
        load();
      },
      revert: (source, load, error) => {
        load();
      },
    };

    const files = ref(
      props.value.map((attachment) => ({
        source: attachment.Name,
        options: {
          type: "local",
        },
      }))
    );

    let lazyFiles = props.value;

    watch(
      () => props.value,
      (val) => {
        if (val.length === 0) {
          lazyFiles = [];
          files.value = [];
        }
      }
    );

    return {
      server,
      files,
      lazyFiles,
      onProcess: async (e, f) => {
        console.log("ON PROCESS START");
        lazyFiles.push({
          Name: f.file.name,
          File: await toBase64(f.file),
        });
        console.log("ON PROCESS END");
        emit("input", lazyFiles);
      },

      onRemove: (e, f) => {
        lazyFiles = lazyFiles.filter((a) => {
          return a.Name !== f.source;
        });
        emit("input", lazyFiles);
      },
      onRevert: (f) => {
        lazyFiles = lazyFiles.filter((a) => {
          return a.Name !== f.source.name;
        });
        emit("input", lazyFiles);
      },
    };
  },
};
</script>

<style></style>
