<template>
  <create-dialog-base @create="create" :loading="creatingProject">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" depressed dark class="" v-bind="attrs" v-on="on">
          Nový projekt
        </v-btn>
      </slot>
    </template>
    <template #headline> Vytvoriť nový projekt </template>
    <template #form>
      <project-form v-model="form"></project-form>
    </template>
  </create-dialog-base>
</template>

<script>
import { getEmptyProject } from "@/services/utils";
import { ref } from "vue-demi";
import ProjectForm from "./Forms/ProjectForm.vue";
import CreateDialogBase from "./CreateDialogBase.vue";
import { useCreateOneProjectMutation } from "@/generated/backend";
import router from "@/router";

export default {
  components: { CreateDialogBase, ProjectForm },
  setup(props, { emit }) {
    const form = ref(getEmptyProject());
    const {
      mutate: createProject,
      loading: creatingProject,
      onDone: creatingFinished,
    } = useCreateOneProjectMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyProject();
        emit("created", data.data.createOneProject);

        router.push({
          name: "ProjectDetail",
          params: { id: data.data.createOneProject.Id, on: true },
        });
      });
      createProject({ input: { project: form.value } });
    };

    return {
      form,
      create,
      creatingProject,
    };
  },
};
</script>

<style></style>
