<template>
  <edit-dialog-base @update="update" :loading="updatingProject">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť projekt {{ lazyProject.Name }}</template>
    <template #form>
      <project-form v-model="lazyProject"></project-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import ProjectForm from "./Forms/ProjectForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneProjectMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
export default {
  components: { EditDialogBase, ProjectForm },
  props: {
    project: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyProject = ref(props.project);
    watch(
      () => props.project,
      (c) => (lazyProject.value = c)
    );

    const {
      mutate: updateProject,
      loading: updatingProject,
      onDone: updatingFinished,
    } = useUpdateOneProjectMutation({});

    const update = (cb) => {
      updatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        projectChange,
        histories,
        createdBy,
        ...updateItem
      } = lazyProject.value;
      updateProject({
        input: { update: updateItem, id: lazyProject.value.Id },
      });
    };

    return {
      update,
      lazyProject,
      updatingProject,
    };
  },
};
</script>

<style></style>
