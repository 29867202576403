<template>
  <v-dialog overlay-color="white" overlay-opacity="100" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" link outlined class="mb-1 mr-1">
        {{ name }}
      </v-chip>
    </template>

    <div v-if="imageUrl">
      <img :src="url" style="margin: auto" />
    </div>
    <div v-else>
      <iframe class="attachmentIframe" :src="url" frameborder="0"></iframe>
    </div>
  </v-dialog>
</template>

<script>
import { computed, watch } from "vue-demi";

export default {
  props: { url: { type: String }, name: { type: String } },
  data: () => ({
    dialog: false,
  }),
  setup(props) {
    const imageUrl =
      props.url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
    return {
      imageUrl,
    };
  },
};
</script>

<style scoped>
.attachmentIframe {
  width: 100%;
  height: 90vh;
  text-align: center;
}
</style>
