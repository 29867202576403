var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.useQuery,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":{ contactedBy: { Id: { eq: _vm.user.Id } } },"tableTitle":("Kontaktovania používateľom " + (_vm.user.Username))},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"UpdatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.UpdatedAt,"ff"))+" ")]}},{key:"company",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('div',[_c('router-link',{attrs:{"to":{
          name: 'CompanyDetail',
          params: { type: _vm.type, id: item.company.Id },
        }}},[_vm._v(_vm._s(item.company.Name))])],1):_c('div',[_vm._v("Vymazaná")])]}},{key:"Email",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.Email}},[_vm._v(_vm._s(item.Email))])]}},{key:"edit-dialog",fn:function(ref){
        var item = ref.item;
return [_c('edit-contact-dialog',{attrs:{"contact":item,"type":_vm.type},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
        var item = ref.item;
return [_c('delete-contact-dialog',{attrs:{"contact":item,"type":_vm.type},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }