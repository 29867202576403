





















import { getEmptyContract } from "@/services/utils";
import { ref } from "vue-demi";
import CreateDialogBase from "./CreateDialogBase.vue";
import {
  useCreateOneContractMutation,
  useCreateOneContractPilverMutation,
} from "@/generated/backend";
import ContractForm from "./Forms/ContractForm.vue";

export default {
  components: { CreateDialogBase, ContractForm },
  props: {
    contractType: { type: String },
    companyType: { type: String },
  },
  setup(props, { emit }) {
    const form = ref(getEmptyContract(props.contractType));

    if (props.companyType === "Pilver") {
      const {
        mutate: createContract,
        loading: creatingContract,
        onDone: creatingFinished,
      } = useCreateOneContractPilverMutation({});

      const create = (cb) => {
        creatingFinished((data) => {
          cb();
          form.value = getEmptyContract(props.contractType);
          emit("created", data);
          console.log(form.value);
        });
        createContract({
          input: {
            contractPilver: form.value,
          },
        });
      };
      return {
        form,
        create,
        creatingContract,
      };
    } else {
      const {
        mutate: createContract,
        loading: creatingContract,
        onDone: creatingFinished,
      } = useCreateOneContractMutation({});

      const create = (cb) => {
        creatingFinished((data) => {
          cb();
          form.value = getEmptyContract(props.contractType);
          emit("created", data);
          console.log(form.value);
        });
        createContract({
          input: {
            contract: form.value,
          },
        });
      };
      return {
        form,
        create,
        creatingContract,
      };
    }
  },
};
