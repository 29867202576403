






































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { ContactsQuery, useContactsQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import DeleteContactDialog from "../Dialogs/DeleteContactDialog.vue";
import router from "@/router";
import {
  ContactPilversQuery,
  useContactPilversQuery,
} from "../../generated/backend";

export default {
  components: {
    BaseTable,
    DeleteContactDialog,
  },
  props: {
    company: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  setup(props) {
    const table = ref(null);

    return {
      table,
      headers: [
        {
          text: "Dátum kontaktovania",
          align: "start",
          value: "UpdatedAt",
          searchable: false,
        },
        {
          text: "Užívateľ",
          value: "contactedBy",
          sortable: false,
          searchable: true,
          searchFunc: (s) => ({
            contactedBy: {
              Username: {
                iLike: `%${s}%`,
              },
            },
          }),
        },
        { text: "Záujem", value: "Interest", searchable: false },
        { text: "Popis", value: "Description", searchable: true },
        { text: "Email", value: "Email", searchable: false },
        { text: "", value: "actions", sortable: false },
      ],

      query:
        props.type === "Pilver" ? useContactPilversQuery : useContactsQuery,

      itemsGetter:
        props.type === "Pilver"
          ? (result: Ref<ContactPilversQuery>) =>
              useResult(result, [], (d) => d.contactPilvers.nodes)
          : (result: Ref<ContactsQuery>) =>
              useResult(result, [], (d) => d.contacts.nodes),
      totalCountGetter:
        props.type === "Pilver"
          ? (result: Ref<ContactPilversQuery>) =>
              useResult(result, 0, (d) => d.contactPilvers.totalCount)
          : (result: Ref<ContactsQuery>) =>
              useResult(result, 0, (d) => d.contacts.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({
          name: "ContactDetail",
          params: { type: props.type, id: item.Id },
        }),
    };
  },
};
