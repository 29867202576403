<template>
  <delete-dialog-base @remove="remove" :loading="deletingCompany">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline>Odstrániť firmu {{ lazyCompany.Name }}</template>
    <template #form>
      Naozaj chcete odstrániť firmu {{ lazyCompany.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import {
  useDeleteOneCompanyMutation,
  useDeleteOneCompanyPilverMutation,
} from "@/generated/backend";
import { ref, watch } from "vue-demi";
import User from "@/services/auth";

export default {
  components: { DeleteDialogBase },
  props: {
    company: {
      type: Object,
    },
    type: { type: String },
  },

  setup(props, { emit }) {
    const lazyCompany = ref(props.company);
    watch(
      () => props.company,
      (c) => (lazyCompany.value = c)
    );

    const {
      mutate: removeCompany,
      loading: deletingCompany,
      onDone: deletingFinished,
    } = props.type === "Pilver"
      ? useDeleteOneCompanyPilverMutation()
      : useDeleteOneCompanyMutation();

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeCompany({
        input: { id: lazyCompany.value.Id },
      });
    };

    return {
      remove,
      lazyCompany,
      deletingCompany,
      User,
    };
  },
};
</script>

<style></style>
