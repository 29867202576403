<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <company-card
      @updated="onEdit"
      @removed="onDelete"
      :loading="loading"
      :type="type"
      :company="company"
    ></company-card>

    <contact-card
      class="mt-8"
      @updated="onContactEdit"
      :loading="loading || (contactLoading && !!company.contact)"
      :contact="contact"
      :company="company"
      :type="type"
      :showEditContact="!!$route.params.on"
    ></contact-card>

    <company-contacts-table
      v-if="company"
      class="mt-8 tw-max-w-4xl tw-mx-auto"
      ref="table"
      :loading="loading"
      :company="company"
      :type="type"
    ></company-contacts-table>
  </div>
</template>

<script>
import CompanyCard from "@/components/Cards/CompanyCard.vue";
import CompanyContactsTable from "@/components/Tables/CompanyContactsTable.vue";
import { useCompanyQuery, useContactQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
import ContactCard from "@/components/Cards/ContactCard.vue";
import { computed, nextTick, ref } from "vue-demi";
import {
  useCompanyPilverQuery,
  useContactPilverQuery,
} from "../generated/backend";

export default {
  components: { CompanyCard, CompanyContactsTable, ContactCard },
  setup(props, { emit }) {
    const table = ref(null);
    const type = router.currentRoute.params.type;
    const companyId = router.currentRoute.params.id;

    const { loading, result, refetch, onResult } =
      type === "Pilver"
        ? useCompanyPilverQuery({
            id: companyId,
          })
        : useCompanyQuery({
            id: companyId,
          });

    const company = useResult(result, null, (r) =>
      type === "Pilver" ? r.companyPilver : r.company
    );

    const enabledContactQuery = computed(() => !!company.value?.contact);

    const {
      loading: contactLoading,
      result: contactResult,
      refetch: contactRefetch,
    } = type === "Pilver"
      ? useContactPilverQuery(
          () => ({
            id: company.value?.contact?.Id,
          }),
          { enabled: enabledContactQuery }
        )
      : useContactQuery(
          () => ({
            id: company.value?.contact?.Id,
          }),
          { enabled: enabledContactQuery }
        );

    const contact = useResult(contactResult, null, (r) =>
      type === "Pilver" ? r.contactPilver : r.contact
    );

    return {
      table,
      loading,
      company,
      type,
      contactLoading,
      contact,
      onContactEdit: async () => {
        await refetch();
        await contactRefetch();
        table.value.reloadTable();
      },
      onDelete: () => router.push({ name: "Companies" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
