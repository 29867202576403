var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.query,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"tableTitle":"Odstránený sprostredkovatelia"},scopedSlots:_vm._u([{key:"DeletedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("luxon")(item.DeletedAt,"ff"))+" ")]}},{key:"deletedBy",fn:function(ref){
var item = ref.item;
return [(item.deletedBy)?_c('router-link',{attrs:{"to":{
        name: 'UserDetail',
        params: { id: item.deletedBy.Id },
      }}},[_vm._v(_vm._s(item.deletedBy.Username))]):_c('span',[_vm._v("Neznámy")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }